import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import style from './StickyHelp.module.scss'

export const StickyHelp = () => {
    return (
        <a href="/get-help">
            <div className={style.circleContainer}>
                <p className="mb-0 h6">Need<br/>help?</p>
            </div>
        </a>
    )
}
