import React, {Fragment} from "react";
import {connect} from 'react-redux';
import {basketActions, basketSelectors} from '../state/basket'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown, faChevronLeft, faChevronUp} from "@fortawesome/free-solid-svg-icons"
import style from "./basket.module.scss"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import arrow from "../images/chevron.svg"
import {Button, Card, Form} from "react-bootstrap"
import {StickyHelp} from "../components/UI/StickyHelp"
import Layout from "../components/Layout"
import { navigate } from 'gatsby';
import axios from "axios";
import queryString from "query-string";

const clearStyle = {
  clear: 'both'
}

const Basket = ({items, addItemToBasket, plusItemCount, minusItemCount, basket, totalValue}) => {

  const handleSubmit = () => {

    // const form = document.getElementById('giftMessageForm');
    //
    // const data = {
    //   message: form.elements.message.value
    // };

    // const postForm = async () => {
    //     axios.post(
    //         // form.action,
    //         // queryString.stringify(data)
    //     ).then((result) => {
    //       console.log(result)
    //
    //       navigate('/checkout')
    //     }
    //     ).catch(()=> {
          navigate('/checkout')
        // })

    // }
    // postForm()
  }

return (
  <>
    <Layout
        seoComponent={{ seoTitle: "Basket page - Little Village", seoDescription: "ready for checkout" }}
        location={{ pathname: "basket" }}>
       <StickyHelp />

      <div className="mb-4 mt-4 mt-lg-11 pt-lg-4 pl-2 rowContainer">
        <FontAwesomeIcon icon={faChevronLeft} className={style.chevronIcon} />
        <a href="/christmas" className="text-danger ml-2  font-weight-bold">Continue shopping</a>
      </div>

      <div className={`d-flex justify-content-between rowContainer px-2 mb-4`}>
        <h2>Your Basket</h2>
        <Button variant="primary" size="lg" className={`mb-4 ${style.checkoutBtn}`} onClick={handleSubmit}>
          Checkout <img src={arrow} alt="right arrow" className="pt-0 rightArrow" />
        </Button>
      </div>

      <div className={`pt-3 pb-6 pt-sm-1 ${style.bgImg}`}>
        <div className="rowContainer">
          <Row className="mt-3 py-4 pl-5 d-none d-sm-flex">
            <Col  sm={6} md={6} className="font-weight-bold">
              Item
            </Col>
            <Col sm={3} md={3} className="font-weight-bold">
              Qty
            </Col>
            <Col sm={3} md={3} className="font-weight-bold">
              Subtotal
            </Col>
          </Row>

          <Row className={`d-flex flex-column `} >

            {basket.map(({item, count},index)=>{


          return(
              <div key={index} className={`d-flex mb-0 pl-3 mt-2 flex-column ${style.box} `}>

                <Col  sm={6}  className="d-flex py-4">
                  <img src={ item.image.sourceUrl}  className="mr-3" width={89} height={118}/>
                  <div>
                    <h5 className="font-weight-bold text-left text-dark" dangerouslySetInnerHTML={{__html: item.name}}/>
                    <p className="mb-4" dangerouslySetInnerHTML={{__html: item.description}}/>
                    <p>Price:<span className="font-weight-bold"> {item.price}</span></p>
                  </div>
                </Col>

                <Col sm={3} >
                  {items.hasOwnProperty(item.id) ?
                    <div className={`d-flex mt-4 `}>
                      <h5 className={style.countBox}>{items[item.id]}</h5>
                      <div className="d-flex flex-column">
                        <button onClick={()=> plusItemCount(item.id)} className={`${style.btn} ${style.upBtn}`}> <FontAwesomeIcon icon={faChevronUp} className={`${style.icon} ${style.upIcon}`} /></button>
                        <button onClick={()=> minusItemCount(item.id)} className={style.btn}> <FontAwesomeIcon icon={faChevronDown} className={style.icon} /></button>
                      </div>
                    </div>
                    :
                    <button onClick={()=> addItemToBasket(item)}>Add to basket</button>
                  }
                </Col>

                <Col sm={3}>
                  <h5 className="font-weight-bold mt-4 text-left">£{item.rawPrice * count.toFixed(2)}</h5>
                </Col>

              </div>
          )
            })}
          </Row>

          {/*<p className="font-weight-bold pl-3 pt-4 pb-2">We would like to include a personal message with each gift voucher. Please include a message here from your family to another family, which we will print and include with the vouchers. Thank you.</p>*/}
          {/*<div  className={`${style.formBox} `}>*/}
          {/*  <Form id="giftMessageForm" action={`${process.env.WORDPRESS_URL}/wp-json/contact-form-7/v1/contact-forms/1609/feedback`} method="post" onSubmit={handleSubmit}>*/}

          {/*    <Fragment>*/}
          {/*      <Card>*/}
          {/*        <Card.Body className="px-5 py-4">*/}
          {/*          <Form.Group controlId="message">*/}
          {/*            <Form.Label>Your message</Form.Label>*/}
          {/*            <Form.Control as="textarea" name="message" rows="4" />*/}
          {/*          </Form.Group>*/}
          {/*        </Card.Body>*/}
          {/*      </Card>*/}
          {/*    </Fragment>*/}
          {/*  </Form>*/}
          {/*</div>*/}

          <div className={`d-flex justify-content-between rowContainer px-2 mb-5 mb-lg-0`}>
            <div className="mb-4 mt-5 pl-2 rowContainer">
              <FontAwesomeIcon icon={faChevronLeft} className={style.chevronIcon} />
              <a href="/christmas" className="text-danger ml-2 font-weight-bold">Continue shopping</a>
            </div>
            <Button variant="primary" size="lg" className={`mb-4 mt-4 ${style.checkoutBtn}`} onClick={handleSubmit}>
              Checkout <img src={arrow} alt="right arrow" className="pt-0 rightArrow" />
            </Button>
          </div>

        </div>
      </div>

    <div style={clearStyle}/>
    </Layout>
  </>
)

}

const mapStateToProps = state => ({
  items: basketSelectors.getItemsIdAndCount(state),
  basket: basketSelectors.getItems(state),
  totalValue: basketSelectors.getTotalValue(state)
});

const mapDispatchToProps = {
  addItemToBasket: basketActions.addItemToBasket,
  plusItemCount: basketActions.plusItemCount,
  minusItemCount: basketActions.minusItemCount,
  setItemCount: basketActions.setItemCount,
};
export default connect(mapStateToProps, mapDispatchToProps) (Basket);

